import React from "react";

import "./TaskFinalFeedback.scss";

function TaskFinalFeedback(props) {
  const { feedback } = props;
  return (
    <div className="Task-final-feedback">
      {feedback.blbTaskFeedbackTitle ? (
        <h2 className="Task-final-feedback__heading">
          {feedback.blbTaskFeedbackTitle}
        </h2>
      ) : null}
      <p className="Task-final-feedback__body">{feedback.blbTaskFeedbackBody}</p>
    </div>
  );
}

export default TaskFinalFeedback;
