import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useDispatch, useSelector } from "react-redux";
import { withScorm } from "react-scorm-provider";

import _ from "lodash";

import "./Task.scss";

import { TASK_OPTION_TYPES, IS_DEBUG, IS_SCORM } from "consts";

import {
  setSelectedTaskGroup,
  setFullCourseState,
  closeTaskOverlay,
  openChapterCompletedOverlay,
  setSectionToVerify
} from "actions";

import {
  postTaskStarted,
  postTaskAnswered,
  postChapterAnswered
} from "tracker-api/statements";
import { getCourseState } from "tracker-api/sessions";

import getUserWithAccessStatus from "selectors/getUserWithAccessStatus";

import useCurrentChapter from "hooks/useCurrentChapter";
import useCurrentSection from "hooks/useCurrentSection";
import useCurrentTaskGroup from "hooks/useCurrentTaskGroup";
import useNextTaskGroup from "hooks/useNextTaskGroup";
import useNextChapter from "hooks/useNextChapter";
import useCurrentEduadminId from "hooks/useCurrentEduadminId";

import ButtonLoading from "components/ButtonLoading/ButtonLoading";
import Error from "components/Error/Error";
import Loading from "components/Loading/Loading";

import TaskHeader from "components/TaskHeader/TaskHeader";
import TaskFinalFeedback from "components/TaskFinalFeedback/TaskFinalFeedback";
import TaskOptionFeedback from "components/TaskOptionFeedback/TaskOptionFeedback";
import TaskInitialContent from "components/TaskInitialContent/TaskInitialContent";
import TaskInteractionWrapper from "components/TaskInteractionWrapper/TaskInteractionWrapper";

import isReflectiveTask from "utils/isReflectiveTask";
import getMaybeRandomTaskFromGroup from "utils/getMaybeRandomTaskFromGroup";
import getTaskType from "utils/getTaskType";
import getTaskSelectedValuesIds from "utils/getTaskSelectedValuesIds";
import debugLog from "utils/debugLog";

function Task(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentTaskGroup = useCurrentTaskGroup();
  const eduadminId = useCurrentEduadminId();
  const user = useSelector(getUserWithAccessStatus);
  const currentTaskGroupData = currentTaskGroup.data || {};

  const currentSection = useCurrentSection();
  const currentChapter = useCurrentChapter();
  const nextTaskGroup = useNextTaskGroup();
  const nextChapter = useNextChapter();

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [hasAnswered, setAnswered] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [showFinalFeedback, setShowFinalFeedback] = useState(false);
  const [nextQuestion, setNextQuestion] = useState(false); //indicates if the "Answer" button is pressed

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sco = useMemo(() => props.sco, []);

  // These arrays are expected to be filled with objects: {optionId: Int, extraData: <whatever> }.
  // extraData is for certain task types where just the prescense of an option is not enough,
  // such as Order
  const [selectedValues, setSelectedValues] = useState([]); // Will be sent to tracker
  const [selectedStepValues, setSelectedStepValues] = useState([]);

  // This temporarily stores an array of (if any) all the sequent
  // steps arrays and will be sent to tracker
  const [stepsTempStorage, setStepsTempStorage] = useState([]);

  const [currentlySelectedTaskInGroup, setCurrentlySelectedTaskInGroup] =
    useState(null);

  const [currentTaskStep, setCurrentTaskStep] = useState(-1);
  const [inheritedOptions, setInheritedOptions] = useState(null);

  const FeedbackToggle =
    currentSection.data.blbCourseSectionInstantFeedbackToggle;

  const currentlySelectedTaskInGroupId = currentlySelectedTaskInGroup
    ? currentlySelectedTaskInGroup.blbTaskId
    : null;
  const currentlySelectedTaskInGroupTitle = currentlySelectedTaskInGroup
    ? currentlySelectedTaskInGroup.titleRendered
    : null;
  const currentlySelectedTaskInGroupMainContent = currentlySelectedTaskInGroup
    ? currentlySelectedTaskInGroup.blbGroupTaskContent.blbTaskMainContent
    : null;
  const currentTaskGroupId = currentTaskGroupData
    ? currentTaskGroupData.blbChapterTaskGroupId
    : null;
  const currentlySelectedTaskInGroupType = currentlySelectedTaskInGroup
    ? getTaskType(currentlySelectedTaskInGroup)
    : null;

  const currentChapterId = currentChapter.data
    ? currentChapter.data.blbChapterId
    : null;

  const nextTaskGroupId = nextTaskGroup.data
    ? nextTaskGroup.data.blbChapterTaskGroupId
    : null;
  const taskOptionAllowIncorrect = currentlySelectedTaskInGroup
    ? currentlySelectedTaskInGroup.blbGroupTaskOptions
        .blbTaskOptionAllowIncorrect
    : null;
  // Get one of the tasks from the group
  // and keep it in state until task group changes
  useDeepCompareEffect(() => {
    // Don't change anything if can't or are not supposed to
    if (_.isEmpty(currentTaskGroupData) || isClosing || hasAnswered) return;

    const extracted = getMaybeRandomTaskFromGroup(currentTaskGroupData);
    setCurrentlySelectedTaskInGroup(extracted);
  }, [currentTaskGroupData, isClosing, hasAnswered]);

  // Report both task group id and task id to tracker
  // when task is started
  useEffect(() => {
    async function _postTaskStarted() {
      await postTaskStarted(
        currentlySelectedTaskInGroupId,
        currentTaskGroupId,
        currentlySelectedTaskInGroupMainContent,
        currentlySelectedTaskInGroupType,
        currentChapterId,
        eduadminId,
        user,
        sco
      );

      // TODO should we reload course state here just to be safe?
    }

    // Only do it if we're able and supposed to
    if (
      currentlySelectedTaskInGroupId &&
      currentTaskGroupId &&
      !hasAnswered &&
      !isClosing
    )
      _postTaskStarted();
  }, [
    currentTaskGroupId,
    currentChapterId,
    currentlySelectedTaskInGroupId,
    currentlySelectedTaskInGroupMainContent,
    currentlySelectedTaskInGroupType,
    hasAnswered,
    isClosing,
    eduadminId,
    user,
    sco
  ]);
  useEffect(() => {
    if (hasAnswered && !isClosing) {
      if (nextTaskGroupId) {
        dispatch(setSelectedTaskGroup(nextTaskGroupId));
      } else {
        close(); // Close the task overlay
        if (!nextChapter.data) {
          // If there is no next chapter, it's time to verify
          dispatch(setSectionToVerify(currentSection.data.blbCourseSectionId));
        } else {
          // Open the completed chapter overlay
          // so that user can proceed to next chapter
          dispatch(openChapterCompletedOverlay());
        }
      }
    }
  }, [
    currentSection,
    hasAnswered,
    dispatch,
    nextTaskGroupId,
    nextChapter,
    eduadminId,
    user,
    currentChapter,
    isClosing,
    sco
  ]);

  useEffect(() => {
    if (isClosing) {
      // Task group is actually unset
      // in pages/Course.js
      dispatch(closeTaskOverlay());
    }
  }, [isClosing, dispatch]);

  const close = () => {
    setIsClosing(true);
  };

  const submitMainTask = useCallback(() => {
    setIsSubmitLoading(true);
    async function _postTaskAnswered() {
      debugLog("Submitting task:");
      debugLog("currentlySelectedTaskInGroup", currentlySelectedTaskInGroup);
      debugLog("currentTaskGroupId", currentTaskGroupId);
      debugLog("selectedValues", selectedValues);
      debugLog("stepsTempStorage", stepsTempStorage);
      debugLog("currentChapterId", currentChapterId);

      let postRes;
      try {
        postRes = await postTaskAnswered(
          currentlySelectedTaskInGroup,
          currentTaskGroupId,
          selectedValues,
          stepsTempStorage,
          currentChapterId,
          eduadminId,
          user,
          sco
        );
      } catch (e) {
        console.error(e);
      }

      // TODO proper error handling
      if (!postRes) {
        setIsSubmitLoading(false);
        return;
      }

      if (!nextTaskGroupId) {
        debugLog("No more taskgroups in chapter, sending chapter answered");
        // Send chapter answered event
        // to tracker.
        await postChapterAnswered(currentChapterId, eduadminId, user, sco);
      }

      // TODO error handle
      const getRes = await getCourseState(eduadminId, user, true, sco);

      // Important that answered is set before
      // course state to avoid retriggering
      // effects above. This effectively
      // unmounts this component, but React
      // doesn't seem to have a problem with
      // performing dispatch below anyway
      setIsSubmitLoading(false);
      setAnswered(true);

      dispatch(setFullCourseState(getRes));
    }
    _postTaskAnswered();
  }, [
    currentlySelectedTaskInGroup,
    selectedValues,
    stepsTempStorage,
    dispatch,
    eduadminId,
    currentChapterId,
    user,
    currentTaskGroupId,
    nextTaskGroupId,
    sco
  ]);

  const [optionsIsDisabled, setOptionsIsDisabled] = useState(false);

  const maybeNextStepOrFinalFeedback = (hasSteps, finalFeedback, type) => {
    const stepsArr =
      currentlySelectedTaskInGroup.blbGroupTaskSteps.blbTaskSteps;

    const nextStep = hasSteps ? stepsArr[currentTaskStep + 1] : null;

    // Persist the current steps' data
    if (currentTaskStep > -1 && !showFinalFeedback) {
      setStepsTempStorage([
        ...stepsTempStorage,
        {
          stepTitle:
            currentlySelectedTaskInGroup.blbGroupTaskSteps.blbTaskSteps[
              currentTaskStep
            ].blbTaskStepMainContent,
          selectedStepValues
        }
      ]);
    }

    // If there is a next step, and we know what to
    // do with it. "inherit" means that the next steps'
    // options will consist of
    if (
      nextStep &&
      nextStep.blbTaskStepType === "inherit" &&
      (type === TASK_OPTION_TYPES.IMAGE || type === TASK_OPTION_TYPES.TEXT)
    ) {
      const optionsKey = `blbTaskOptionType${type}Options`;
      const idKey = `blbTaskType${type}OptionId`;

      const optionsToInherit = currentlySelectedTaskInGroup.blbGroupTaskOptions[
        optionsKey
      ].filter(option => {
        const selectedValuesIds = getTaskSelectedValuesIds(selectedValues);
        return selectedValuesIds.includes(option[idKey]);
      });

      // Prepare for next step
      setInheritedOptions(optionsToInherit);
      setCurrentTaskStep(currentTaskStep + 1);
      setSelectedStepValues([]);
    } else if (finalFeedback && !showFinalFeedback) {
      setShowFinalFeedback(true);
    } else {
      submitMainTask();
    }
  };

  if (currentTaskGroup.loading || nextTaskGroup.loading) return <Loading />;
  if (currentTaskGroup.error)
    return (
      <Error
        error={currentTaskGroup.error || "Failed to load current taskgroup"}
      />
    );

  if (hasAnswered && nextTaskGroup.error)
    return (
      <Error error={nextTaskGroup.error || "Failed to load next taskgroup"} />
    );

  const hasSelected = !_.isEmpty(selectedValues);
  // const hasStepSelected = !_.isEmpty(selectedStepValues);

  // currentlySelectedTaskInGroup can be null before the effect had chance to rerender.
  if (!currentlySelectedTaskInGroup) return null;

  // Steps only supported for text or image atm
  const hasSteps =
    !_.isEmpty(currentlySelectedTaskInGroup.blbGroupTaskSteps.blbTaskSteps) &&
    (currentlySelectedTaskInGroupType === TASK_OPTION_TYPES.TEXT ||
      currentlySelectedTaskInGroupType === TASK_OPTION_TYPES.IMAGE);

  const isOnAdditionalStep = currentTaskStep > -1;

  const finalFeedback = !_.isEmpty(
    currentlySelectedTaskInGroup.blbGroupTaskOptions.blbTaskFeedback
  )
    ? currentlySelectedTaskInGroup.blbGroupTaskOptions.blbTaskFeedback[0]
    : null;
  const isReflective = isReflectiveTask(currentlySelectedTaskInGroup);
  const submitHandler = e => {
    e.preventDefault();
    setNextQuestion(true);
    if (
      !optionsIsDisabled &&
      !showFinalFeedback &&
      !isReflective &&
      currentlySelectedTaskInGroupType !== "Slider"
    ) {
      setOptionsIsDisabled(true);
    } else {
      setOptionsIsDisabled(false);
      maybeNextStepOrFinalFeedback(
        hasSteps,
        finalFeedback,
        currentlySelectedTaskInGroupType
      );
    }
  };
  return (
    <section className="Task">
      <form
        onSubmit={e => {
          submitHandler(e);
        }}
      >
        <div className="Task__header">
          <TaskHeader
            currentChapter={currentChapter}
            taskData={currentlySelectedTaskInGroup}
            taskNumber={currentTaskGroupData.taskNumber}
            closeFn={close}
            currentTaskStep={currentTaskStep}
            isOnAdditionalStep={isOnAdditionalStep}
            isOnFinalFeedback={showFinalFeedback}
          />
        </div>

        <div className="Task__content">
          {!isOnAdditionalStep && !showFinalFeedback && !hasSelected ? (
            <TaskInitialContent taskData={currentlySelectedTaskInGroup} />
          ) : null}
          {showFinalFeedback ? (
            <TaskFinalFeedback feedback={finalFeedback} />
          ) : isOnAdditionalStep ? (
            <TaskInteractionWrapper
              taskData={currentlySelectedTaskInGroup}
              selectedValues={selectedStepValues}
              selectFn={setSelectedStepValues}
              type={currentlySelectedTaskInGroupType}
              stepOptionsOverride={inheritedOptions}
              currentTaskStep={currentTaskStep}
            />
          ) : (
            <TaskInteractionWrapper
              taskData={currentlySelectedTaskInGroup}
              selectedValues={selectedValues}
              selectFn={setSelectedValues}
              type={currentlySelectedTaskInGroupType}
              currentTaskStep={currentTaskStep}
              showAnswer={FeedbackToggle && !isReflective && nextQuestion}
              isDisabled={optionsIsDisabled}
            />
          )}

          {/* Since options on subsequent task steps are
          the same inherited ones, don't show option 
          feedback again */}
          {!isOnAdditionalStep && !showFinalFeedback ? (
            <TaskOptionFeedback
              taskData={currentlySelectedTaskInGroup}
              selectedValues={selectedValues}
              type={currentlySelectedTaskInGroupType}
            />
          ) : null}
        </div>
        <div className="Task__submit">
          <button
            type="submit"
            disabled={
              (!isOnAdditionalStep && !showFinalFeedback && !hasSelected) ||
              /* (isOnAdditionalStep && !hasStepSelected) || // We're allowing blank answers for steps for now */
              isSubmitLoading
            }
            className="Task__submitbtn"
          >
            {showFinalFeedback
              ? t("I_UNDERSTAND")
              : optionsIsDisabled
              ? t("MOVE_ON")
              : isReflective
              ? t("ANSWER_AND_MOVE_ON")
              : t("ANSWER")}
            {isSubmitLoading ? <ButtonLoading /> : null}
          </button>
          <button type="button" className="Task__cancellink" onClick={close}>
            <span className="Task__cancellink-highlight">{t("CANCEL")}:</span>{" "}
            {t("BACK_TO_FACTS_AND_READING")}
          </button>
        </div>
        {IS_DEBUG ? (
          <div className="Task__d">
            <div>task title: {currentlySelectedTaskInGroupTitle}</div>
            selectedValues:
            <ul>
              {selectedValues.map((_selectedValue, i) => {
                return (
                  <li key={_selectedValue.optionId}>
                    {_selectedValue.optionId}
                    <br />
                    {_selectedValue.extraData}
                  </li>
                );
              })}
            </ul>
            selectedStepValues:
            <ul>
              {selectedStepValues.map((_selectedValue, i) => {
                return (
                  <li key={_selectedValue.optionId}>
                    {_selectedValue.optionId}
                    <br />
                    {_selectedValue.extraData}
                  </li>
                );
              })}
            </ul>
            <div></div>
            <div>
              task group id{" :"}
              {currentTaskGroupData.blbChapterTaskGroupId}
            </div>
            <div>task id: {currentlySelectedTaskInGroup.id}</div>
            <div>
              Allow incorrect:{" "}
              {currentlySelectedTaskInGroup.blbGroupTaskOptions
                .blbTaskOptionAllowIncorrect
                ? "yes"
                : "no"}
            </div>
          </div>
        ) : null}
      </form>
    </section>
  );
}

const Comp = IS_SCORM ? withScorm()(Task) : Task;

export default Comp;
