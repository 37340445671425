import { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  setAccessStatus,
  setFullCourseState,
  setAvailableLanguages
} from "actions";

import getUserWithAccessStatus from "selectors/getUserWithAccessStatus";

import useCurrentEduadminId from "hooks/useCurrentEduadminId";
import useCurrentCourse from "hooks/useCurrentCourse";

import { postCourseInitialized } from "tracker-api/statements";
import { getCourseState, getAccessStatus } from "tracker-api/sessions";

import debugLog from "utils/debugLog";
import { IS_BYPASS_SSO, IS_DEMO, IS_SCORM } from "consts";
import envConfig from "environment.json";

const NEW_PARTICIPANT_VERSION = "latest";

/**
 *
 * This hook is designed to be called initially on any
 * React route that uses course data and state for the user.
 * It does several things, in a set order:
 *
 * 1a. Check if the logged in user is a participant
 * 1b. If it is, also check for an existing course state.
 * 2:  Get the course data from the CMS, if user is a participant
 * 3:  Post a initialize statement to tracker, unless the user already has a course state
 * 4:  Get and store the course state 
 *
 */

function useSetupCourse(sco) {
  const dispatch = useDispatch();

  const user = useSelector(getUserWithAccessStatus);
  // To be able to use user within
  // effects without depending on it,
  // to avoid endless loops.
  const userRef = useRef(user);
  useEffect(() => {
    userRef.current = user;
  });

  const eduadminId = useCurrentEduadminId();
  const {
    data: courseData,
    version: courseVersion,
    loading: courseDataLoading,
    fetchData: fetchCourseContentData
  } = useCurrentCourse();

  // Loading flags
  const [isLoadingInit, setIsLoadingInit] = useState(false);
  const [isLoadingAccess, setIsLoadingAccess] = useState(false);
  const [isLoadingCourseState, setIsLoadingCourseState] = useState(false);

  // Done flags
  const [isDoneInit, setIsDoneInit] = useState(false);
  const [isDoneAccess, setIsDoneAccess] = useState(false);
  const [isDoneCourseState, setIsDoneCourseState] = useState(false);

  // Errors
  const [initError, setInitError] = useState(null);
  const [accessError, setAccessError] = useState(null);

  // accessStatus.hasActiveCourse/hasCompleteCourse does not accuratley describe
  // the state of the course for the user at this time :( 
  // Maybe we can improve the tracker in the future, so we don't need 1b below!
  // const isContinuedSession = !!userRef.current?.accessStatus?.hasActiveCourse;
  const [isContinuedSession, setIsContinuedSession] = useState(false);

  // Set up variables to act on later
  const hasUser = !!userRef.current || IS_BYPASS_SSO;
  const statusWasFetched = !!userRef.current?.accessStatus || IS_DEMO;
  const hasAccess = !!userRef.current?.accessStatus?.hasAccess;
  const activeCourseVersion =
    userRef.current?.accessStatus?.activeCourseVersion || null;
  const isPreview = !!userRef.current?.accessStatus?.isPreview;
  const isParticipant = hasAccess || isPreview || IS_BYPASS_SSO || IS_DEMO;
  // Use a memo variable to assign this
  // variable conditinally dependent on 
  // several factors
  const wantedVersion = useMemo(() => {
    if(!isDoneAccess) return null;

    // If there is a course version, we know that this is 
    // non-legacy continued session.
    if (activeCourseVersion) return activeCourseVersion;
    if (IS_DEMO) return NEW_PARTICIPANT_VERSION;
    if (isParticipant) {
      // If there is no active course version, but user has either
      // active or complete course, this is an older course
      // state from before course versions were introduced.
      if (isContinuedSession) {
        return "legacy";
      } else {
        return NEW_PARTICIPANT_VERSION;
      }
    }
    return null;
  }, [isDoneAccess, activeCourseVersion, isParticipant, isContinuedSession]);

  // Will be used further down by effects
  const _getCourseState = useCallback(async () => {
    setIsLoadingCourseState(true);

    if (isParticipant) {
      // Silent error flag because we expect 404 sometimes,
      // so no try/catch needed.
      const getRes = await getCourseState(eduadminId, userRef.current, true, sco);

      if (getRes) {
        debugLog("Got full course state", getRes);

        setIsContinuedSession(true);

        dispatch(setFullCourseState(getRes));
      }
    }

    setIsLoadingCourseState(false);
    
  }, [
    dispatch,
    eduadminId,
    isParticipant,
    setIsLoadingCourseState,
    setIsContinuedSession,
    sco
  ]);

  // 1a. Check if user is participant and
  // store in redux
  useEffect(() => {
    const _checkParticipant = async () => {
      setIsLoadingAccess(true);

      let accessStatusRes;
      try {
        accessStatusRes = await getAccessStatus(
          eduadminId,
          userRef.current,
          false
        );
      } catch (e) {
        setAccessError(e);
      }

      if (accessStatusRes) {
        // This includes course version info as well, activeCourseVersion.
        // It will change the wantedVersion variable,
        // triggering a getCourseState below.
        dispatch(setAccessStatus(accessStatusRes));

        // Do not set done flag here - instead
        // defer it to 1b below.
      } else {
        // If request fails unexpectedly,
        // this skips 1b below.
        dispatch(setAccessStatus(null));
        setIsDoneAccess(true);
      }

      setIsLoadingAccess(false);
      
    };
    if (
      hasUser &&
      !isLoadingAccess &&
      !accessError &&
      eduadminId &&
      !isParticipant &&
      !isDoneAccess &&
      !statusWasFetched
    ) {
      debugLog("Checking if participant");
      _checkParticipant();
    } 
  }, [
    dispatch,
    hasUser,
    isLoadingAccess,
    isParticipant,
    accessError,
    eduadminId,
    isDoneAccess,
    statusWasFetched,
    setIsLoadingAccess,
    setAccessError,
    setIsDoneAccess
  ]);

  // 1b. Get course state here, so that we can figure
  // out later if this is a new or a continued session.
  // See hasActiveCourse
  useEffect(() => {
    const _getInterimCourseState = async () => {
      if(isParticipant) await _getCourseState();
      setIsDoneAccess(true);
    };
    if(statusWasFetched && !isDoneAccess && !isLoadingAccess) {
      _getInterimCourseState();
    }
  }, [isParticipant, statusWasFetched, isLoadingAccess, isDoneAccess, setIsDoneAccess, _getCourseState])

  // 2. Execute get request for course content data once we have access info.
  useEffect(() => {
    if (isParticipant && wantedVersion && !courseDataLoading && !courseData) {
      fetchCourseContentData(wantedVersion);
    }
  }, [
    isParticipant,
    wantedVersion,
    courseDataLoading,
    courseData,
    fetchCourseContentData
  ]);

  // 3. Init statement if needed
  useEffect(() => {
    async function _postCourseInitialized() {
      setIsLoadingInit(true);
      if (wantedVersion === NEW_PARTICIPANT_VERSION) {
        debugLog("Initializing in tracker");
        try {
          await postCourseInitialized(
            courseData.course,
            eduadminId,
            courseVersion,
            userRef.current,
            sco
          );

          // Add the course version to the session, so that we can easily access it
          // on the user object in other contexts later. This will change wantedVersion
          // memo variable above, triggering a getCourseState.
          // Should only be null here if IS_DEMO.
          if (userRef.current) {
            dispatch(
              setAccessStatus({
                ...userRef.current.accessStatus,
                activeCourseVersion: courseVersion
              })
            );
          }
        } catch (e) {
          setInitError(e);
        }
      } else {
        debugLog("Skipping initialization");
      }

      setIsDoneInit(true);
      setIsLoadingInit(false);
    }

    if (
      isParticipant &&
      !isLoadingInit &&
      !initError &&
      !isDoneInit &&
      courseData &&
      courseVersion &&
      (eduadminId || IS_DEMO || IS_SCORM)
    ) {
      // Wait for CMS course id before posting to tracker
      _postCourseInitialized();
    } else if (!isParticipant && isDoneAccess) {
      // If there is no user, short-circut to
      // 'done' so that callee can display login
      setIsDoneInit(true);
    }
  }, [
    isLoadingInit,
    initError,
    isDoneInit,
    isDoneAccess,
    wantedVersion,
    courseData,
    dispatch,
    eduadminId,
    courseVersion,
    isParticipant,
    sco,
    setIsDoneInit,
    setIsLoadingInit
  ]);

  // 4. Get and store course state for final use,
  // after we've done init statement to tracker.
  // Also, make sure to wait for courseData request
  // before doing this.
  useEffect(() => {
    const _getFinalCourseState = async () => {
      // Skip it if it's already here from 1b.
      if(!isContinuedSession) await _getCourseState();
      setIsDoneCourseState(true);
    }
    if (isDoneInit && courseData){
      _getFinalCourseState();
    }
  }, [
    isParticipant,
    isDoneInit,
    courseData,
    setIsDoneCourseState,
    isContinuedSession,
    _getCourseState
  ]);

  // Independent and async from the other stuff: 
  // Get and store available languages and slugs for SCT courses
  useEffect(() => {
    if (!IS_DEMO && !IS_SCORM) {
      const _getLanguages = async function () {
        const url = envConfig.SCT_LANGUAGES_REST_URI;

        const res = await fetch(url, {
          method: "GET",
          headers: { "Content-Type": "application/json" }
        })
          .then(_res => {
            if (!_res.ok) return {};
            return _res.json();
          })
          .then(json => {
            dispatch(setAvailableLanguages(json));
          })
          .catch(err => {
            console.error(err);
          });
        return res;
      };
      _getLanguages();
    }
  }, [dispatch]);

  return {
    isLoading: isLoadingAccess || isLoadingInit || isLoadingCourseState,
    isDone: isDoneAccess && isDoneInit && isDoneCourseState,
    accessError,
    initError,
    isParticipant
  };
}

export default useSetupCourse;
