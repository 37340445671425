import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import "./TaskHeader.scss";

// import numToAlphabetLetter from "utils/numToAlphabetLetter";

import OverlayCloseButton from "components/OverlayCloseButton/OverlayCloseButton";
import SoundPlayer from "components/SoundPlayer/SoundPlayer";
import TaskContent from "components/TaskContent/TaskContent";
import TaskGroupProgress from "components/TaskGroupProgress/TaskGroupProgress";

function TaskHeader(props) {
  const { t } = useTranslation();
  const {
    taskData,
    // taskNumber,
    currentTaskStep,
    isOnAdditionalStep,
    isOnFinalFeedback,
    currentChapter
  } = props;

  // const thatManyTasks = data.blbGroupChapterTasks.blbChapterTasks.length;

  // const taskNumberDisplay = hasSteps
  //   ? `${taskNumber}${numToAlphabetLetter(currentTaskStep + 1)}` // Main task will be a, next step b etc
  //   : `${taskNumber}`;

  const cls =
    "Task-header" + (props.isInFacts ? " Task-header--isInFacts" : "");

  // Final feedback, steps, and main question all
  // have separate sound files
  const soundUrl =
    isOnFinalFeedback &&
    !_.isEmpty(taskData.blbGroupTaskOptions.blbTaskFeedback) &&
    taskData.blbGroupTaskOptions.blbTaskFeedback[0].blbTaskFeedbackSoundFile
      ? taskData.blbGroupTaskOptions.blbTaskFeedback[0].blbTaskFeedbackSoundFile
          .mediaItemUrl
      : isOnAdditionalStep &&
        !_.isEmpty(
          taskData.blbGroupTaskSteps.blbTaskSteps[currentTaskStep]
            .blbTaskStepSoundFile
        )
      ? taskData.blbGroupTaskSteps.blbTaskSteps[currentTaskStep]
          .blbTaskStepSoundFile.mediaItemUrl
      : taskData.blbGroupTaskContent.blbTaskMainSoundFile &&
        !isOnFinalFeedback &&
        !isOnAdditionalStep
      ? taskData.blbGroupTaskContent.blbTaskMainSoundFile.mediaItemUrl
      : null;

  return (
    <header className={cls}>
      {props.closeFn ? <OverlayCloseButton fn={props.closeFn} /> : null}

      {/* Had to use key here to trigger SoundPlayer
      rerender when moving between task/steps/final feedback */}
      {soundUrl ? (
        <div key={soundUrl} className="Task-header__sound">
          <SoundPlayer sourceUrl={soundUrl} playText={t("LISTEN_TO_TASK")} />
        </div>
      ) : null}

      {/* Don't render chapter info heading if we're in facts section */}
      {props.isInFacts ? null : currentChapter && currentChapter.data ? (
        <h2 className="Task-header__heading">
          <TaskGroupProgress />
          <span className="Task-header__chapter">
            {t("CHAPTER_X", {
              chapterNumber: currentChapter.data.chapterNumber
            })}
            : {currentChapter.data.titleRendered}
          </span>
          {/* &nbsp;
        <span>
          {t("TASK_X_OF_X", { taskNumber: taskNumberDisplay, thatManyTasks })}
        </span> */}
        </h2>
      ) : null}

      {!isOnAdditionalStep &&
      !isOnFinalFeedback &&
      taskData.blbGroupTaskContent.blbTaskMainContent ? (
        <p className="Task-header__main-content">
          {taskData.blbGroupTaskContent.blbTaskMainContent}
        </p>
      ) : null}
      {isOnAdditionalStep &&
      !isOnFinalFeedback &&
      taskData.blbGroupTaskSteps.blbTaskSteps[currentTaskStep]
        .blbTaskStepMainContent ? (
        <p className="Task-header__main-content">
          {
            taskData.blbGroupTaskSteps.blbTaskSteps[currentTaskStep]
              .blbTaskStepMainContent
          }
        </p>
      ) : null}

      {/* Don't render additional task content if we're in facts section */}
      {props.isInFacts || isOnFinalFeedback ? null : (
        <div className="Task-header__content">
          {isOnAdditionalStep &&
          taskData.blbGroupTaskSteps.blbTaskSteps[currentTaskStep]
            .blbTaskStepContent ? (
            <TaskContent
              taskContent={
                taskData.blbGroupTaskSteps.blbTaskSteps[currentTaskStep]
                  .blbTaskStepContent
              }
            />
          ) : taskData.blbGroupTaskContent ? (
            <TaskContent
              taskContent={taskData.blbGroupTaskContent.blbTaskContent}
            />
          ) : null}
        </div>
      )}

      {!isOnAdditionalStep &&
      !isOnFinalFeedback &&
      taskData.blbGroupTaskOptions.blbTaskOptionsTitle ? (
        <p className="Task-header__instruction">
          {taskData.blbGroupTaskOptions.blbTaskOptionsTitle}
        </p>
      ) : null}
      {isOnAdditionalStep &&
      !isOnFinalFeedback &&
      taskData.blbGroupTaskSteps.blbTaskSteps[currentTaskStep]
        .blbTaskStepOptionsTitle ? (
        <p className="Task-header__instruction">
          {
            taskData.blbGroupTaskSteps.blbTaskSteps[currentTaskStep]
              .blbTaskStepOptionsTitle
          }
        </p>
      ) : null}
    </header>
  );
}

export default TaskHeader;
